import React from 'react';
import Link from 'next/link';
import HeadMeta from '../components/common/HeadMeta';

export default function NotFound() {
  const metaProps = {
    title: 'Pagina no encontrada',
    description: 'EntreCoach',
    keywords: 'EntreCoach',
  };
  return (
    <>
      <HeadMeta {...metaProps} />
      <main className="grid min-h-screen px-6 py-24 bg-white place-items-center sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-brand-main">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight payfair sm:text-5xl text-brand-main">
            Página No Encontrada
          </h1>
          <p className="mt-6 text-base font-semibold leading-7 text-gray-600">
            Lo sentimos, no pudimos encontrar la página que estás buscando.
          </p>

          <p className="mt-6 text-base leading-7 text-gray-600">
            Por favor, regresa al inicio y continúa explorando nuestras opciones para descubrir cómo
            podemos ayudarte a alcanzar tus metas.
          </p>
          <div className="flex items-center justify-center mt-10 gap-x-6">
            <Link
              href="/"
              className="px-5 py-3 font-semibold text-white transition-all duration-200 payfair bg-brand-main hover:bg-brand-main "
            >
              Volver al inicio
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}
